<template>
    <section class="others">
        <div class="container">
            Patience, it's still a work in progress :)
        </div>

        <!-- Footer -->
        <Footer/>
    </section>
</template>

<script>
import Footer from '@/components/Footer';

export default {
    name: 'Others',
    components: {
        Footer,
    },
    data: () => {
        return {};
    },
    mounted () {},
    beforeDestroy() {},
    methods: {},
}
</script>

<style lang="scss">
.others {
    margin-top: 5.5rem;
    width: 100%;
}
</style>
