<template>
    <section class="footer">
        <div class="container">
            &copy; 2021 Joseph Budiarto
        </div>
    </section>
</template>
<script>
export default {
    name: 'Footer',
}
</script>
<style lang="scss" scoped>
.footer {
    display: block;
    position: relative;
    top: -1rem;
    font-size: $size-4;

    .container {
        margin-bottom: 2rem;
    }
}
</style>
